import React from 'react';
import Layout from 'shared/components/layout';
import { compose, setDisplayName } from 'recompose';
import { map } from 'lodash/fp';
import { connect } from 'react-redux';
import { cryptoNumber } from 'shared/utils/formatter';
import { Container, Row, Col } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import { date } from 'relient/formatters';
import { withI18N } from 'shared/contexts';
import EarningList from '../financial-center/earning-list';
import selector from './agency-home-selector';
import s from '../financial-center/table.scss';

export default compose(
  connect(selector),
  withStyles(s),
  withI18N,
  setDisplayName(__filename),
)(({
  accountAmount,
  incomes,
  invitationCode,
  earnings,
  agencyInvitationCode,
  origin,
  agencies,
  salesCommissions,
  customerInvitationCode,
  i18n,
}) => (
  <Layout>
    <Container>
      <Row>
        <Col>
          <p>
            {i18n('totalAccount')}: {accountAmount}
          </p>

          <p>
            {i18n('invitationLink')}: {origin}/auth/register?invitationCode={invitationCode}
          </p>

          <p>
            {i18n('invitationCode')}: {customerInvitationCode}
          </p>

          <p>
            {i18n('invitationLinkForAgency')}: {origin}/auth/register?agencyInvitationCode={agencyInvitationCode}
          </p>

          <h4><p>{i18n('mySalesCommission')}:</p></h4>
          <div className={s.Root}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{i18n('salesCommission')}</th>
                  <th scope="col">{i18n('sourceAgencyName')}</th>
                  <th scope="col">{i18n('date')}</th>
                </tr>
              </thead>
              <tbody>
                {map(({
                  id,
                  commissionAmount,
                  currencyCode,
                  createdAt,
                  sourceAgencyName,
                }) => (
                  <tr key={id}>
                    <td>
                      {currencyCode === 'BTC' ? cryptoNumber()(commissionAmount) : commissionAmount} {currencyCode}
                    </td>
                    <td>{sourceAgencyName}</td>
                    <td>
                      {date()(createdAt)}
                    </td>
                  </tr>
                ))(salesCommissions)}
                {salesCommissions.length === 0 && (
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>{i18n('empty')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <h4><p>{i18n('subAgencySalesCommission')}:</p></h4>
          <div className={s.Root}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{i18n('agency')}</th>
                  <th scope="col">{i18n('salesCommission')}</th>
                </tr>
              </thead>
              <tbody>
                {map(({
                  id,
                  name,
                  salesCommissionDisplay,
                }) => (
                  <tr key={id}>
                    <td>
                      {name && name.replace('Agency-', '')}
                    </td>
                    <td>
                      {salesCommissionDisplay || i18n('empty')}
                    </td>
                  </tr>
                ))(agencies)}
                {agencies.length === 0 && (
                  <tr>
                    <td colSpan={2} style={{ textAlign: 'center' }}>{i18n('empty')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <h4><p>{i18n('managementFee')}</p></h4>
          {incomes.length > 0 && map(({ shortName, income }) => (
            <p key={shortName}>
              {shortName} {i18n('income')}: {cryptoNumber()(income)}
            </p>
          ))(incomes)}
          {incomes.length === 0 && (
            <p>
              BTC {i18n('income')}: 0
            </p>
          )}
          <EarningList earnings={earnings} />
        </Col>
      </Row>
    </Container>
  </Layout>
));

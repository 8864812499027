import {
  flow,
  filter,
  propEq,
  groupBy,
  prop,
  map,
  sumBy,
  sortBy,
  size,
  mapValues,
  values,
  join,
} from 'lodash/fp';
import { getEntityArray, getEntity } from 'relient/selectors';
import { getCurrentAccount } from 'shared/selectors/account';
import { cryptoNumber } from 'shared/utils/formatter';

const mapValuesWithKey = mapValues.convert({ cap: false });
const mapWithKeys = map.convert({ cap: false });

export default (state) => {
  const agencyId = flow(getCurrentAccount, prop('agencyId'))(state);
  const earnings = flow(
    getEntityArray('earning'),
    filter(propEq('agencyId', agencyId)),
    map(({ cryptocurrencyId, ...rest }) => ({
      ...rest,
      cryptocurrencyId,
      cryptocurrency: getEntity(`cryptocurrency.${cryptocurrencyId}`)(state),
    })),
    sortBy(({ createdAt }) => -new Date(createdAt).getTime()),
  )(state);

  return {
    earnings,
    salesCommissions: flow(
      getEntityArray('salesCommission'),
      filter(propEq('agencyId', agencyId)),
      map((salesCommission) => {
        const sourceAgencyName = getEntity(`agency.${salesCommission.sourceAgencyId}.name`)(state);
        return {
          ...salesCommission,
          sourceAgencyName: sourceAgencyName ? sourceAgencyName.replace('Agency-', '') : 'Myself',
        };
      }),
    )(state),
    invitationCode: getEntity(`agency.${agencyId}.invitationCode`)(state),
    customerInvitationCode: flow(getCurrentAccount, prop('customerInvitationCode'))(state),
    agencyInvitationCode: getEntity(`agency.${agencyId}.agencyInvitationCode`)(state),
    agencies: flow(
      getEntityArray('agency'),
      filter(propEq('parentAgencyId', agencyId)),
      map((agency) => ({
        ...agency,
        salesCommissionDisplay: flow(
          getEntityArray('salesCommission'),
          filter(propEq('agencyId', agency.id)),
          groupBy(prop('currencyCode')),
          mapValuesWithKey((
            groupedSalesCommissions,
            currencyCode,
          ) => {
            const total = sumBy('commissionAmount')(groupedSalesCommissions);
            return `${currencyCode}: ${currencyCode === 'USD' ? total : cryptoNumber()(total)}`;
          }),
          values,
          join(' / '),
        )(state),
      })),
    )(state),
    incomes: flow(
      groupBy('cryptocurrencyId'),
      mapWithKeys((groupedEarnings, key) => ({
        shortName: flow(
          getEntity(`cryptocurrency.${key}`),
          prop('shortName'),
        )(state),
        income: sumBy(prop('agencyIncome'))(groupedEarnings),
      })),
    )(earnings),
    accountAmount: flow(
      getEntity(`agency.${agencyId}`),
      prop('invitedAccountIds'),
      size,
    )(state),
  };
};

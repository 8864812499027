exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1e3sx{text-align:center;margin-bottom:40px;padding:10px;background:#161c5e}._1e3sx th,._1e3sx td{padding:10px 2px}._1e3sx thead th{color:white;font-size:14px;border:none;font-weight:normal}._1e3sx td{font-size:12px;border-top:0;border-bottom:1px solid #596670}\n", ""]);

// exports
exports.locals = {
	"Root": "_1e3sx"
};
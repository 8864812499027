import React from 'react';
import { array } from 'prop-types';
import { map, slice } from 'lodash/fp';
import withStyles from 'isomorphic-style-loader/withStyles';
import Pagination from 'shared/components/pagination';
import { withI18N } from 'shared/contexts';
import { compose, setDisplayName, setPropTypes, withState, withHandlers, withProps } from 'recompose';
import { cryptoNumber } from 'shared/utils/formatter';
import { date } from 'relient/formatters';

import s from './table.scss';

export default compose(
  setPropTypes({
    earnings: array.isRequired,
  }),
  withState('currentPage', 'setCurrentPage', 0),
  withProps(({ currentPage, earnings }) => ({
    dataSource: slice(
      currentPage * 10,
      (currentPage * 10) + 10,
    )(earnings),
  })),
  withHandlers({
    onPageChange: ({ setCurrentPage }) => async ({ selected }) => {
      setCurrentPage(selected);
    },
  }),
  withStyles(s),
  withI18N,
  setDisplayName(__filename),
)(({
  onPageChange,
  earnings,
  dataSource,
  i18n,
}) => (
  <div className={s.Root}>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">{i18n('date')}</th>
          <th scope="col">{i18n('proceed')}</th>
        </tr>
      </thead>
      <tbody>
        {map(({
          id,
          startAt,
          customerIncome,
          customerIncomeUsd,
        }) => (
          <tr key={id}>
            <td>{date()(startAt)}</td>
            <td>{cryptoNumber()(customerIncome)} BTC {customerIncomeUsd ? `($ ${customerIncomeUsd})` : ''}</td>
          </tr>
        ))(dataSource)}
      </tbody>
    </table>
    {earnings.length === 0 && (
      <div style={{ heigth: 30 }}>&nbsp;</div>
    )}
    {earnings.length > 0 && (
      <Pagination
        pageCount={Math.ceil(earnings.length / 10)}
        onPageChange={onPageChange}
      />
    )}
  </div>
));

import React from 'react';
import { HOME } from 'shared/constants/features';
import { readMine as readMyWallet } from 'shared/actions/wallet';
import { getCurrentAccount } from 'shared/selectors/account';
import { readMine as readMyEarning } from 'shared/actions/earning';
import { AGENCY } from 'shared/constants/role-key';
import {
  toggleLoginModal,
  toggleRegisterModal,
} from 'shared/actions/global';
import Home from './home';
import AgencyHome from './agency-home';

const action = async ({ store: { dispatch, getState }, origin, path }) => {
  const currentAccount = getCurrentAccount(getState());
  if (currentAccount && currentAccount.roleKey === AGENCY) {
    await dispatch(readMyWallet());
    await dispatch(readMyEarning({ size: 500 }));
    return {
      component: <AgencyHome origin={origin} />,
    };
  }
  if (path === '/auth/register' && __BROWSER__) {
    setTimeout(() => {
      dispatch(toggleRegisterModal());
    }, 1000);
  } else if (path === '/auth/login' && __BROWSER__) {
    setTimeout(() => {
      dispatch(toggleLoginModal());
    }, 1000);
  }
  return {
    component: <Home />,
  };
};

export default async () => [{
  path: '',
  feature: HOME,
  action,
}, {
  path: '/auth/register',
  feature: HOME,
  action,
}, {
  path: '/auth/login',
  feature: HOME,
  action,
}];

import React from 'react';
import { func, number, node } from 'prop-types';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import ReactPaginate from 'react-paginate';

export default compose(
  setPropTypes({
    forcePage: number,
    pageCount: number.isRequired,
    pageRangeDisplayed: number,
    marginPagesDisplayed: number,
    onPageChange: func.isRequired,
    previousLabel: node,
    nextLabel: node,
  }),
  setDisplayName(__filename),
)(({
  previousLabel,
  nextLabel,
  pageCount,
  pageRangeDisplayed,
  marginPagesDisplayed,
  onPageChange,
  forcePage,
}) => (
  <ReactPaginate
    forcePage={forcePage}
    activeClassName="active"
    pageClassName="page-item"
    pageLinkClassName="page-link"
    previousClassName="page-item"
    previousLinkClassName="page-link"
    nextClassName="page-item"
    nextLinkClassName="page-link"
    containerClassName="pagination justify-content-center"
    previousLabel={previousLabel || (<span>&laquo;</span>)}
    nextLabel={nextLabel || (<span>&raquo;</span>)}
    pageCount={pageCount}
    pageRangeDisplayed={pageRangeDisplayed || 3}
    marginPagesDisplayed={marginPagesDisplayed || 2}
    onPageChange={onPageChange}
  />
));

import { keys } from 'lodash/fp';
import getText from 'relient/get-text';
import getOptions from 'relient/get-options';

export const DYNAMIC = 'DYNAMIC';
export const FIXED = 'FIXED';

const textMap = {
  [DYNAMIC]: 'dynamicContract',
  [FIXED]: 'fixedContract',
};

export const contractMethods = keys(textMap);
export const getContractMethodText = getText(textMap);
export const contractMethodOptions = getOptions(textMap);

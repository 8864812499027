import { keys } from 'lodash/fp';
import getText from 'relient/get-text';
import getOptions from 'relient/get-options';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

const textMap = {
  [ACTIVE]: 'contractActive',
  [INACTIVE]: 'contractInactive',
};

export const contractStatuses = keys(textMap);
export const getContractStatusText = getText(textMap);
export const contractStatusOptions = getOptions(textMap);

import React from 'react';
import Layout from 'shared/components/layout';
import { connect } from 'react-redux';
import { compose, setDisplayName, withState, withProps } from 'recompose';
import { withI18N } from 'shared/contexts';
import withStyles from 'isomorphic-style-loader/withStyles';
import { toggleRegisterModal as toggleRegisterModalAction } from 'shared/actions/global';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import BigNumber from 'bignumber.js';
import { cryptoNumber } from 'shared/utils/formatter';
import { flow, find, map } from 'lodash/fp';
import { getEntityArray, getEntity } from 'relient/selectors';
import { DYNAMIC } from 'shared/constants/contract-method';
import Link from 'shared/components/link';
import { PRODUCT_CENTER } from 'shared/constants/features';
import classNames from 'classnames';
import isMobileViewSelector from 'shared/selectors/is-mobile-view';
import { push as pushAction } from 'relient/actions/history';
import { ACTIVE } from 'shared/constants/contract-status';
import { JP } from 'shared/constants/languages';
import s from './home.scss';

export default compose(
  withI18N,
  withStyles(s),
  withState('hashRateDisplay', 'setHashRateDisplay', 1),
  withState('hashRate', 'setHashRate', 1),
  withState('registerEmail', 'setRegisterEmail', null),
  withState('registerEmailDisplay', 'setRegisterEmailDisplay', ''),
  connect((state, { hashRate }) => {
    const earning = flow(
      getEntityArray('contract'),
      find(({ method, estimateEarning, status }) => method === DYNAMIC && estimateEarning > 0 && status === ACTIVE),
      (contract) => {
        if (!contract) {
          return null;
        }
        const { estimateEarning, unit } = contract;
        return new BigNumber(estimateEarning).multipliedBy(hashRate).div(unit).multipliedBy(1e12)
          .toNumber();
      },
    )(state);
    return {
      earning,
      earningUSD: earning && new BigNumber(earning).div(global.exchangeRate.USDtoBTC).toNumber(),
      isMobileView: isMobileViewSelector(state),
      isLogin: getEntity('auth.isLogin')(state),
    };
  }, {
    toggleRegisterModal: toggleRegisterModalAction,
    push: pushAction,
  }),
  withProps(({ i18n }) => ({
    integrations: [{
      title: i18n('homeIntegrationTitle1', { br: <br /> }),
      subTitle: i18n('homeIntegrationSubTitle1', { br: <br /> }),
      icon: '/images/home/integration-1.png',
    }, {
      title: i18n('homeIntegrationTitle2'),
      subTitle: i18n('homeIntegrationSubTitle2', { br: <br /> }),
      icon: '/images/home/integration-2.png',
    }, {
      title: i18n('homeIntegrationTitle3'),
      subTitle: i18n('homeIntegrationSubTitle3', { br: <br /> }),
      icon: '/images/home/integration-3.png',
    }],
    features: [{
      title: i18n('homeFeatureTitle1'),
      subTitle: i18n('homeFeatureSubTitle1', { br: <br /> }),
      icon: '/images/home/feature-1.png',
    }, {
      title: i18n('homeFeatureTitle2'),
      subTitle: i18n('homeFeatureSubTitle2', { br: <br /> }),
      icon: '/images/home/feature-2.png',
    }, {
      title: i18n('homeFeatureTitle3'),
      subTitle: i18n('homeFeatureSubTitle3', { br: <br /> }),
      icon: '/images/home/feature-3.png',
    }],
  })),
  setDisplayName(__filename),
)(({
  setHashRate,
  earning,
  earningUSD,
  hashRateDisplay,
  setHashRateDisplay,
  isMobileView,
  registerEmail,
  setRegisterEmail,
  toggleRegisterModal,
  registerEmailDisplay,
  setRegisterEmailDisplay,
  isLogin,
  push,
  i18n,
  price,
  integrations,
  features,
  language,
}) => (
  <Layout style={{ position: 'relative' }} registerEmail={registerEmail} hideSubHeader>
    <Container>
      <Row>
        <Col className="text-center">
          {!isMobileView && <div className={s.TopRight} />}
          {!isMobileView && <div className={s.TopLeft} />}
          <div className={s.Title}>
            {i18n('homeTitle', { br: <br /> })}
          </div>
          {!isMobileView && (
            <div className={s.SubTitle}>
              {i18n('homeSubTitle', { br: <br /> })}
            </div>
          )}
          <img className={s.HeroImage} src="/images/home/background.png" alt="" />
          {isMobileView && (
            <div className={s.SubTitle}>
              {i18n('homeSubTitle', { br: '' })}
            </div>
          )}
        </Col>
      </Row>
    </Container>

    <div className={s.HowMuchContainer}>
      <Container>
        <Row>
          <Col md={3} className={s.HowMuch}>
            {i18n('homeHowMuchEarn', { br: (!isMobileView && language !== JP) ? <br /> : '' })}
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center">
            <input
              className={s.Input}
              onChange={({ target: { value } }) => setHashRateDisplay(value)}
              value={hashRateDisplay}
            />
            <div className={s.Unit}>
              TH/s
            </div>
            <button type="button" className="button-light" onClick={() => setHashRate(hashRateDisplay)}>
              {i18n('homeCalculate')}
            </button>
          </Col>
          <Col md={3} className="d-flex flex-column justify-content-center">
            <span className={s.EarningDescription}>{i18n('homeEstimateRevenue')}</span> {!isMobileView && <br />}
            <span className={s.Earning}>{cryptoNumber()(earning)} BTC ({price(earningUSD)})</span>
          </Col>
        </Row>
      </Container>
    </div>

    <Container>
      <Row>
        <Col className="text-center">
          {!isMobileView && <div className={s.FeatureSubBrand}>{i18n('homeFeatureSubBrand1')}</div>}
          <div className={s.FeatureBrand}>
            {i18n('homeFeatureBrand1', { br: !isMobileView ? <br /> : '' })}
          </div>
          <div className={s.Features}>
            {map(({ title, subTitle, icon }) => (
              <div key={title} className={s.Feature}>
                <img className={s.FeatureIcon} src={icon} alt={title} />
                <div className={s.FeatureTitle}>{title}</div>
                <div className={s.FeatureSubTitle}>{subTitle}</div>
                {!isMobileView && <img className={s.FeatureArrow} src="/images/home/arrow-right.png" alt="" />}
              </div>
            ))(features)}
          </div>
          <Link className={classNames('button-light', 'd-inline-block', s.GetStartNow)} feature={PRODUCT_CENTER}>
            {i18n('homeGetStartNow')}
          </Link>
        </Col>
      </Row>
    </Container>

    <div className={s.HowContainer}>
      <Container>
        <Row>
          <Col>
            {!isMobileView && <div className={s.FeatureSubBrand}>{i18n('homeFeatureSubBrand2')}</div>}
            <div className={s.FeatureBrand}>
              {i18n('homeFeatureBrand2')}
            </div>
            <div className={s.HowWork1}>
              <div className={s.HowWorkTitle}>{i18n('signUp')}</div>
              <div className={s.HowWorkDescription}>{i18n('works1')}</div>
            </div>
            <div className={s.HowWork2}>
              <div className={s.HowWorkTitle}>{i18n('purchase')}</div>
              <div className={s.HowWorkDescription}>{i18n('works2')}</div>
            </div>
            <div className={s.HowWork3}>
              <div className={s.HowWorkTitle}>{i18n('enjoy')}</div>
              <div className={s.HowWorkDescription}>{i18n('works3')}</div>
            </div>
            {isMobileView ? (
              <img style={{ width: '100%', marginTop: -80 }} src="/images/home/how-it-works-mobile.png" alt="" />
            ) : (
              <img style={{ marginLeft: -110 }} src="/images/home/how-it-works.png" alt="" />
            )}
          </Col>
        </Row>
      </Container>
    </div>

    <Container>
      <Row className={s.IntegrationContainer}>
        <Col md={6}>
          {!isMobileView && <div className={s.IntegrationSubTitle}>{i18n('homeIntegrationSubTitle')}</div>}
          <div className={s.IntegrationTitle}>
            {i18n('homeIntegrationTitle', { br1: <br />, br2: <br /> })}
          </div>
          {!isMobileView && (
            <div className={s.IntegrationDescription}>
              {i18n('homeIntegrationDescription1')}<span className={s.IntegrationHighlight}>{i18n('homeIntegrationHighlight1')}</span> <br />
              {i18n('homeIntegrationDescription2')}<span className={s.IntegrationHighlight}>{i18n('homeIntegrationHighlight2')}</span> <br />
              {i18n('homeIntegrationDescription3', { br1: <br />, br2: <br /> })}
            </div>
          )}
        </Col>
        <Col md={1} />
        <Col md={5}>
          {map(({ title, subTitle, icon }) => (
            <div className={classNames('d-flex', s.IntegrationItem)} key={title}>
              <img className={s.IntegrationItemIcon} src={icon} alt={title} />
              <div>
                <div className={s.IntegrationItemTitle}>{title}</div>
                <div className={s.IntegrationItemSubTitle}>{subTitle}</div>
              </div>
            </div>
          ))(integrations)}
        </Col>
      </Row>

      {!isMobileView && (
        <Row>
          <Col>
            <div className={s.StartContainer}>
              <img className={s.EmailIcon} src="/images/home/email.png" alt="" />
              <div>
                <div className={s.EnterEmail}>{i18n('homeEnterEmail')}</div>
                <div className={s.StartNow}>{i18n('homeStartNow')}</div>
              </div>
              <div className={s.Subscription}>
                {!isLogin && (
                  <input
                    className={classNames(s.EmailInput, 'input')}
                    placeholder={i18n('homeEnterEmailPlaceHolder')}
                    value={registerEmailDisplay}
                    onChange={({ target: { value } }) => setRegisterEmailDisplay(value)}
                  />
                )}
                <button
                  type="button"
                  className={classNames('button-dark', isLogin && s.GetStartWhenLogin)}
                  onClick={() => {
                    if (isLogin) {
                      push(PRODUCT_CENTER);
                    } else {
                      setRegisterEmail(registerEmailDisplay);
                      toggleRegisterModal();
                    }
                  }}
                >
                  {i18n('homeGetStart')}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row className={s.ExploreContainer}>
        <Col className={s.ExploreLeft} md={6}>
          <div className={s.ExploreTitle}>{i18n('homeExploreTitle1', { br: <br /> })}</div>
          <div className={s.ExploreTitle}>{i18n('homeExploreTitle2')}<span className={s.ExploreTitleHighlight}>#Bitcoin</span></div>
          <div className={s.ExploreDescription}>
            {i18n('homeExploreDescription', { br1: !isMobileView ? <br /> : '', br2: !isMobileView ? <br /> : '' })}
          </div>
        </Col>
        <Col className={s.ExploreRight} md={6}>
          <div className={s.ExploreRightDescription}>
            {i18n('homeExploreRightDescription1')}
            {!isMobileView && i18n('homeExploreRightDescription2')}
          </div>
          <Link className={classNames('button-light', 'd-inline-block', s.GetStartedNow)} feature={PRODUCT_CENTER}>
            {i18n('homeGetStartNow')}
          </Link>
        </Col>
      </Row>
    </Container>
    <div className={s.BottomCircle} />
  </Layout>
));
